const contactForm = document.querySelector("#contact-form");

const fullNameInput = document.querySelector("#full-name");
const phoneNumberInput = document.querySelector("#phone-number");
const emailInput = document.querySelector("#email");
const toPurchaseInput = document.querySelector("#purchase");
const toSellInput = document.querySelector("#sell");
const forGeneralInquiryInput = document.querySelector("#general");
const companyInput = document.querySelector("#company");
const websiteInput = document.querySelector("#website");
const countryInput = document.querySelector("#country");
const messageInput = document.querySelector("#message");
const customerLocationInput = document.querySelector("#customer-location");
const submitButton = document.querySelector("#submit");
const successMessage = document.querySelector("#success-message");

const radioInputs = document.querySelectorAll(".radio-input");

const handleRadioInputChange = (event) => {
  event.preventDefault();

  const { value } = event.target;

  if (value === "Purchase Glam and Glits products") {
    companyInput.required = false;
    websiteInput.required = false;
    countryInput.required = false;
    customerLocationInput.required = true;
    companyInput.parentElement.classList.add("hidden");
    websiteInput.parentElement.classList.add("hidden");
    countryInput.parentElement.classList.add("hidden");
    customerLocationInput.parentElement.classList.remove("hidden");
  } else if (value === "Sell Glam and Glits products") {
    companyInput.required = true;
    websiteInput.required = true;
    countryInput.required = true;
    customerLocationInput.required = false;
    companyInput.parentElement.classList.remove("hidden");
    websiteInput.parentElement.classList.remove("hidden");
    countryInput.parentElement.classList.remove("hidden");
    customerLocationInput.parentElement.classList.add("hidden");
  } else if (value === "General Inquiries") {
    companyInput.required = false;
    websiteInput.required = false;
    countryInput.required = false;
    customerLocationInput.required = false;
    companyInput.parentElement.classList.add("hidden");
    websiteInput.parentElement.classList.add("hidden");
    countryInput.parentElement.classList.add("hidden");
    customerLocationInput.parentElement.classList.add("hidden");
  }
};

const handleFormSubmit = (event) => {
  event.preventDefault();

  submitButton.disabled = true;

  const full_name = fullNameInput.value;
  const phone_number = phoneNumberInput.value;
  const email = emailInput.value;

  let reason_for_contacting;
  if (toPurchaseInput.checked) {
    reason_for_contacting = toPurchaseInput.value;
  } else if (toSellInput.checked) {
    reason_for_contacting = toSellInput.value;
  } else if (forGeneralInquiryInput.checked) {
    reason_for_contacting = forGeneralInquiryInput.value;
  }

  const customer_location = customerLocationInput.value;
  const company = companyInput.value;
  const website = websiteInput.value;
  const country = countryInput.value;
  const message = messageInput.value;

  const formData = {
    full_name,
    phone_number,
    email,
    reason_for_contacting,
    message,
  };

  if (company) formData.company = company;
  if (website) formData.website = website;
  if (country) formData.country = country;
  if (customer_location) formData.customer_location = customer_location;

  const originUrl = window.location.origin;
  const allowedUrls = ['https://glamandglits.com', 'https://www.glamandglits.com', 'https://d1cs037tfrhcw0.cloudfront.net', 'https://dbchn92zx1xjg.cloudfront.net'];
  const apiUrl = allowedUrls.includes(originUrl) ? 'https://sleepy-ridge-54731-86f3fc602d40.herokuapp.com' : 'http://localhost:8080';

  fetch(`${apiUrl}/api/gg/contact`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  })
    .then(res => res.json())
    .then((data) => {
      contactForm.classList.add("hidden");
      successMessage.classList.remove("hidden");
    })
    .catch(err => console.error(err));
};

radioInputs.forEach((input) => input.addEventListener("change", handleRadioInputChange));
contactForm.addEventListener("submit", handleFormSubmit);
